import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomeView from './views/Home';
import PrivacyView from './views/Privacy';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
        <Switch>
          <Route exact path="/" component={HomeView} />
          <Route path="/privacy" component={PrivacyView} />
          <Route component={HomeView} />
        </Switch>
    </div>
    </BrowserRouter>
  );
}

export default App;
